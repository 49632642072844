import React, { useState, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { routeController } from "../../src/api/controllers/route";
import { transportController } from "../../src/api/controllers/transport";
import { scheduleController } from "../../src/api/controllers/schedule";

import {
  Row,
  Col,
  Button,
  Select,
  Timeline,
  Form,
  Spin,
  Divider,
  Empty,
  DatePicker,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
import { Text } from "../../src/components/Typography";
import { CardStyleSecondary } from "../../src/components/Card";
import { Label } from "../../src/components/Label";
import TableSchedule from "../../src/components/TableSchedule";
import Alert from "../../src/components/Alert";

import RESPONSE_MESSAGE from "../../src/helpers/constants/responseMessage";
import SUBROUTES from "../../src/helpers/constants/subRoute";

const FormSchedule = dynamic(
  () => import("../../src/components/Forms/FormSchedule"),
  { ssr: false, loading: () => <>Loading...</> }
);
const Modal = dynamic(() => import("../../src/components/Modal"), {
  ssr: false,
});

const Schedule = () => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const dateFormat2 = "YYYY-MM-DD";
  const currentDate = moment().format(dateFormat2);
  const sevenDate = moment().add(7, "days").format(dateFormat2);
  const timeFormat = "HH:mm";
  const router = useRouter();
  const [form] = Form.useForm();
  const [type, setType] = useState("daily");
  const [modal, setModal] = useState({ type: "", isShow: false, data: {} });
  const [selected, setSelected] = useState({ routeId: "", transportId: "" });
  const [disabled, setDisabled] = useState({ date: "", time: "", id: "" });
  const [dateRange, setDateRange] = useState([]);
  const [dateData, setDateData] = useState({ startDate: "", endDate: "" });
  const [checkbox, setCheckbox] = useState(SUBROUTES);
  const [timePicker, setTimePicker] = useState({ time: "", id: "" });
  const [startDateSchedule, setStartDateSchedule] = useState({ startDate: "" });
  const [selectDeparture, setSelectDeparture] = useState({ time: "", id: "" });
  const [selectArrival, setSelectArrival] = useState({ time: "", id: "" });

  const { data: routes } = useQuery("get-routes", () =>
    routeController().getRoute({ limit: 10, page: 1 })
  );

  const {
    data: transports,
    isLoading: isLoadingTransport,
    isFetching: isFetchingTransport,
    refetch: refetchTransport,
  } = useQuery("get-transport", () =>
    transportController().getTransport({ limit: 10, page: 1 })
  );

  const separateRoute = routes?.data
    .filter((item) => item.id === selected.routeId)
    .map((v) => v.subRouteLists)
    .reduce((prev, curr) => prev.concat(curr), []);

  const onSearchSchedule = () => {
    if (type === "weekly") {
      setDateData({ startDate: dateRange[0], endDate: dateRange[1] });
    }
    if (type === "daily") {
      setDateData({
        startDate: moment(dateRange).format(dateFormat2),
        endDate: moment(dateRange).format(dateFormat2),
      });
    }
  };

  const onSearchScheduleFromDateRange = (startDate, endDate) => {
    if (startDate !== "" || endDate !== "") {
      return [startDate, endDate];
    }
    return [currentDate, currentDate];
  };

  const {
    data: schedule,
    isFetching: isFetchingSchedule,
    isLoading: isLoadingSchedule,
    refetch: refetchSchedule,
  } = useQuery(["get-schedule", dateData.startDate, dateData.endDate], () =>
    scheduleController().getSchedule({
      limit: 2000,
      page: 1,
      startDate: onSearchScheduleFromDateRange(
        dateData.startDate,
        dateData.endDate
      )[0],
      endDate: onSearchScheduleFromDateRange(
        dateData.startDate,
        dateData.endDate
      )[1],
    })
  );

  const onChangeCheckbox = (e) => {
    const { name } = e.target;
    setCheckbox({ ...checkbox, [name]: e.target.checked });
  };

  const selectRouteId = routes?.data.filter(item => item.id === selected.routeId)

  const onFinishFormSchedule = (values) => {
    const subRouteListId = separateRoute
      .filter((item) => item.order > 0)
      .map((v) => v.id);

    // * Convert Object to Array
    const group = {};
    for (const [key, value] of Object.entries(values)) {
      const section = key.charAt(key.length - 1);
      if (group[section]) {
        group[section][key] = value;
      } else {
        group[section] = { [key]: value };
      }
    }

    const resultGroup = Object.values(group).slice(0, -1);
    // * Rename Object key
    const getSubRoutes = resultGroup.map((value, index) => {
      const transportItem = separateRoute[index];
      value = Object.keys(value).reduce(
        (prev, key) => ({
          ...prev,
          [key === `seat${index}`
            ? `seat`
            : key && key === `checked${index}`
            ? `checked`
            : key && key === `transport${index}`
            ? `transport`
            : key && key === `arrival${index}`
            ? `arrival`
            : key && key === `departure${index}`
            ? `departure`
            : key]: value[key],
        }),
        {}
      );
      return {
        quota:
          value.seat !== null && value.seat !== "" && value.seat !== undefined
            ? Number(value.seat)
            : 0,
        departureTime:
          value.departure !== null &&
          value.departure !== "" &&
          value.departure !== undefined
            ? moment(value.departure._d).format("HH:mm")
            : "00:00",
        arrivalTime:
          value.arrival !== null &&
          value.arrival !== "" &&
          value.arrival !== undefined
            ? moment(value.arrival._d).format("HH:mm")
            : "00:00",
        isActive: Object.values(checkbox)[index],
        subRouteListId: subRouteListId[index],
        transportId:
          value.transport === undefined || value.transport === null
            ? transportItem.transport.id
            : value.transport,
      };
    });

    
    const result = {
      dateBegin: moment(values.beginDate._d).format(dateFormat2),
      dateEnd: moment(values.endDate._d).format(dateFormat2),
      routeId: values.route,
      isActive: true,
      routeEngName: selectRouteId?.[0].engName,
      routeThName: selectRouteId?.[0].thName,
      subRouteSchedules: getSubRoutes,
    };

    createSchedule(result);
  };

  const {
    mutate: createSchedule,
    isLoading: isLoadingCreateSchedule,
    isSuccess: isSuccessCreateSchedule,
  } = useMutation(scheduleController().createSchedule, {
    onSuccess: async (res) => {
      onCloseModal();
      Alert.success({
        description: `Sub-routes ${RESPONSE_MESSAGE.CREATED_SUCCESS}`,
      });
      return refetchSchedule();
    },
    onError: async (err) => {
      const description = mapMessageError(err.errors[0].message);
      Alert.error({
        description: description,
      });
    },
  });

  const initialFormSubRoutes = [...Array(separateRoute?.length)].map(() => {
    return {
      arrival: null,
      checked: null,
      departure: null,
      seat: null,
      transport: null,
    };
  });

  // * Reset Fields SubRoute
  const resetFieldSubRoutes = initialFormSubRoutes.map((value, index) => {
    return (value = Object.keys(value).reduce(
      (prev, key) => ({
        ...prev,
        [key === `seat`
          ? `seat${index}`
          : key && key === `checked`
          ? `checked${index}`
          : key && key === `transport`
          ? `transport${index}`
          : key && key === `arrival`
          ? `arrival${index}`
          : key && key === `departure`
          ? `departure${index}`
          : key]: null,
      }),
      {}
    ));
  });

  const disabledTime = moment(disabled.time).format("HH");
  const onChangeRoutes = (value) => {
    setSelected((prevState) => ({
      ...prevState,
      routeId: value,
    }));
    form.setFieldsValue(
      resetFieldSubRoutes.reduce((prev, cur) => ({ ...prev, ...cur }), {})
    );
    if (value) {
      setDisabled({ date: "", time: "" });
      setTimePicker({ time: "", id: "" });
      setSelectDeparture({ time: "", id: "" });
      setSelectArrival({ time: "", id: "" });
    }
  };

  // useEffect(() => {
  // const onResetTime = () => {
  //   if (disabledTime  === "Invalid date") {
  //   form.setFieldsValue(
  //     resetFieldSubRoutes.reduce((prev, cur) => ({ ...prev, ...cur }), {})
  //   );
  //   }
  // };
  // onResetTime();
  // }, [resetFieldSubRoutes]);

  const onChangeStartDate = (dateString) => {
    setStartDateSchedule({ startDate: dateString });
  };

  const onChangeDepartureTime = (timeString, index) => {
    setDisabled({ time: timeString, id: index });
  };

  const onChangeArrivalTimePicker = (time, index) => {
    setTimePicker({ time: time, id: index });
  };

  const onSelectDepartureTime = (time, index) => {
    setSelectDeparture({ time: time, id: index });
    if (index === 0) {
      form.setFieldsValue({
        departure0: time,
      });
    } else if (index === 1) {
      form.setFieldsValue({
        departure1: time,
      });
    } else if (index === 2) {
      form.setFieldsValue({
        departure2: time,
      });
    } else if (index === 3) {
      form.setFieldsValue({
        departure3: time,
      });
    } else {
      form.setFieldsValue({
        departure4: time,
      });
    }
  };

  const onSelectArrivalTime = (time, index) => {
    setSelectArrival({ time: time, id: index });
    if (index === 0) {
      form.setFieldsValue({
        arrival0: time,
      });
    } else if (index === 1) {
      form.setFieldsValue({
        arrival1: time,
      });
    } else if (index === 2) {
      form.setFieldsValue({
        arrival2: time,
      });
    } else if (index === 3) {
      form.setFieldsValue({
        arrival3: time,
      });
    } else {
      form.setFieldsValue({
        arrival4: time,
      });
    }
  };

  const getDisabledHours = () => {
    let hours = [];
    const departure = moment(selectDeparture.time).format("HH");
    const arrival = moment(selectArrival.time).format("HH");
    if (disabledTime === "Invalid date") return;
    if (arrival > departure || departure >= arrival) {
      for (let i = 0; i < moment(disabled.time).hour(); i++) {
        hours.push(i);
      }
      for (let i = 0; i < moment(timePicker.time).hour(); i++) {
        hours.push(i);
      }
      return hours;
    } else {
      return;
    }
  };

  const getDisabledMinutes = () => {
    let minutes = [];
    const departure = moment(selectDeparture.time).format("HH");
    const arrival = moment(selectArrival.time).format("HH");
    if (disabledTime === "Invalid date") return;
    if (arrival > departure || departure > arrival) {
      return;
    } else {
      for (var i = 0; i < moment(selectDeparture.time).minute(); i++) {
        minutes.push(i);
      }
      for (var i = 0; i < moment(selectArrival.time).minute(); i++) {
        minutes.push(i);
      }
      return minutes;
    }
  };

  const getMaxSeat = (index, initSeat) => {
    const formValue = form.getFieldValue();
    let maxSeat = initSeat;
    if (formValue[`transport${index}`] !== undefined && transports.data) {
      const transportIndex = transports.data.findIndex(
        (each) => each.id === formValue[`transport${index}`]
      );
      if (transportIndex !== -1) {
        maxSeat = transports.data[transportIndex].totalSeat;
      }
    }
    return maxSeat;
  };

  const onChangeTransports = (value, option) => {
    setSelected((prevState) => ({
      ...prevState,
      transportId: value,
    }));
    form.validateFields([`seat${option.index}`]);
  };

  const onOpenModal = ({ type, data }) => {
    if (type === "create") {
      return setModal((prevState) => ({
        ...prevState,
        type,
        isShow: true,
      }));
    }
  };

  const onCloseModal = () => {
    form.resetFields();
    setModal({ type: "", isShow: false, data: {} });
    setSelected({ route: "" });
    setDisabled({ date: "", time: "" });
    setTimePicker({ time: "", id: "" });
    setSelectDeparture({ time: "", id: "" });
    setSelectArrival({ time: "", id: "" });
  };

  const handleChangeType = (value) => {
    setType(value);
    if (value === "daily") {
      setDateData({ startDate: currentDate, endDate: currentDate });
    }
    if (value === "weekly") {
      setDateData({ startDate: currentDate, endDate: sevenDate });
    }
  };

  const onScheduleDetail = (id) => {
    if (id)
      return router.push({
        pathname: "/schedule/[id]",
        query: { id: id },
      });
  };

  const onChangeSearchScheduleDaily = (dateString) => {
    if (dateString !== null) {
      setDateRange(dateString);
    } else {
      setDateRange(moment());
    }
  };

  const onChangeSearchScheduleWeekly = (_, dateString) => {
    if (dateString[0] === "") {
      router.reload();
    } else {
      setDateRange(dateString);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, data) => (
        <Label
          border="primary"
          background="primary"
          title={moment(data.serviceDate).format("ddd, Do MMM")}
        >
          {moment(data.serviceDate).format("ddd, Do MMM")}
        </Label>
      ),
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      render: (_, data) => (
        <Row key={data.id}>
          <CardStyleSecondary
            padding="0.5rem 1rem"
            height="40px"
            borderColor="lightBlue"
            background="lightBlue"
          >
            <Row align="middle" justify="space-between">
              <Text className="display">
                {data.subRouteSchedules[0]?.departureTime?.slice(0, -3)}
              </Text>
              <Col>
                <Image
                  alt="songserm"
                  src={"/icons/icon-ferry.webp"}
                  width={20}
                  height={20}
                />
                <Text className="ml-1" level="6">
                  {data.routeEngName}
                </Text>
              </Col>
              <FormOutlined
                onClick={() => onScheduleDetail(data.id)}
                style={{ fontSize: "20px" }}
              />
            </Row>
          </CardStyleSecondary>
        </Row>
      ),
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      render: (_, data) => (
        <Row>
          {data.subRouteSchedules
            .map((v) => v)
            .filter((item) => item.subRoute.order > 0)
            .map((v, index) => (
              <Label
                key={index}
                title={`${v.subRoute?.from.thName} - ${v.subRoute?.to.thName}`}
                border="secondary"
                background="secondary"
                color="white"
                className="mr-half"
                width="60px"
              >
                {v.subRoute?.from.code}
              </Label>
            ))}
        </Row>
      ),
    },
  ];

  const findIndexSchedule = schedule?.data
    .map((v) => moment(v.serviceDate).format("YYYY-MM-DD"))
    .findIndex((value) => value > moment().add(6, "days").format("YYYY-MM-DD"));

  return (
    <>
      {type === "daily" ? (
        <Row align="middle" gutter={[18, 18]}>
          <Col xs={17} md={10} lg={7} xl={6}>
            <DatePicker
              name="calendar_daily"
              onChange={onChangeSearchScheduleDaily}
            />
          </Col>
          <Col xs={7} md={10} lg={7} xl={6}>
            <Button name="search_daily" onClick={onSearchSchedule}>
              Search
            </Button>
          </Col>
        </Row>
      ) : (
        <Row align="middle" gutter={[18, 18]}>
          <Col xs={17} md={10} lg={7} xl={6}>
            <RangePicker
              name="calendar_weekly"
              onChange={onChangeSearchScheduleWeekly}
            />
          </Col>
          <Col xs={7} md={10} lg={7} xl={6}>
            <Button name="search_weekly" onClick={onSearchSchedule}>
              Search
            </Button>
          </Col>
        </Row>
      )}

      <Col span={24}>
        <Row className="mt-2 mb-2" align="middle" justify="space-between">
          <Col>
            <Text className="mr-1">View</Text>
            <Select
              style={{ width: 100 }}
              defaultValue="daily"
              onChange={handleChangeType}
              id="select_daily"
            >
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
            </Select>
          </Col>
          <Col align="end">
            <Button
              name="add_schedule"
              onClick={() => onOpenModal({ type: "create" })}
            >
              + Add Schedule
            </Button>
          </Col>
        </Row>
      </Col>

      {type === "daily" && (
        <Spin
          spinning={isLoadingSchedule || isFetchingSchedule}
          tip="Loading..."
        >
          <Row gutter={[18, 18]}>
            {schedule?.data?.length > 0 ? (
              schedule?.data?.map((schedules, index) => (
                <Col
                  key={schedules.id}
                  xs={24}
                  md={24}
                  lg={24}
                  xl={12}
                  span={12}
                >
                  <CardStyleSecondary
                    padding="1rem"
                    borderColor="ghostWhite"
                    background="ghostWhite"
                  >
                    <CardStyleSecondary
                      padding="0.4rem 1rem"
                      height="40px"
                      borderColor="lightBlue"
                      background="lightBlue"
                    >
                      <Row align="middle" justify="space-between">
                        <Text className="display">
                          {schedules.subRouteSchedules
                            .filter((item) => item.subRoute.order > 0)[0]
                            ?.departureTime.slice(0, -3)}
                        </Text>
                        <Col>
                          <Image
                            alt="songserm"
                            src={"/icons/icon-ferry.webp"}
                            width={20}
                            height={20}
                          />
                          <Text className="ml-1" level="6">
                            {schedules.routeEngName}
                          </Text>
                        </Col>
                        <FormOutlined
                          name="edit_schedule"
                          onClick={() => onScheduleDetail(schedules.id)}
                          style={{ fontSize: "20px" }}
                        />
                      </Row>
                    </CardStyleSecondary>
                    <Row className="mb-1" justify="space-between">
                      <Text className="mt-1" color="darkBlue" weight="600">
                        {`${
                          schedules.subRouteSchedules.filter(
                            (item) => item.subRoute.order > 0
                          )[0]?.transport?.engName
                        } | ${
                          schedules.subRouteSchedules.filter(
                            (item) => item.subRoute.order > 0
                          )[0]?.quota
                        } seats`}
                      </Text>
                      {schedules.isActive && (
                        <Text className="mt-1" color="success" weight="600">
                          {"Enable"}
                        </Text>
                      )}
                      {!schedules.isActive && (
                        <Text className="mt-1" color="error" weight="600">
                          {"Disable"}
                        </Text>
                      )}
                    </Row>
                    <Row gutter={[6]} justify="space-between">
                      <Col span={24}>
                        <Row className="mb-1">
                          <Col md={6} xl={7} span={7} align="center"></Col>
                          <Col md={6} xl={6} span={6} align="center">
                            <Text color="black" weight="600">
                              GET ON
                            </Text>
                          </Col>
                          <Col md={6} xl={6} span={6} align="center">
                            <Text color="black" weight="600">
                              GET OFF
                            </Text>
                          </Col>
                          <Col md={6} xl={5} span={5} align="center">
                            <Text color="black" weight="600">
                              AVAILABLE
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={10} sm={7} lg={5} xl={7} span={6}>
                        <Timeline mode="left">
                          {schedules.subRouteSchedules
                            .map((v) => v)
                            .filter((item) => item.subRoute.order > 0)
                            .map((v) => (
                              <Timeline.Item
                                key={v.id}
                                color="dark"
                                label={v.departureTime.slice(0, -3)}
                              >
                                <Label title={v.subRoute?.from.engName}>
                                  {v.subRoute?.from.code}
                                </Label>
                              </Timeline.Item>
                            ))}
                          {schedules.subRouteSchedules
                            .slice(-1)
                            .map((value) => (
                              <Timeline.Item key={value.id} color="dark">
                                <Label title={value.subRoute?.to.engName}>
                                  {value.subRoute?.to.code}
                                </Label>
                              </Timeline.Item>
                            ))}
                        </Timeline>
                      </Col>
                      <Col
                        xs={14}
                        sm={17}
                        lg={18}
                        xl={16}
                        span={18}
                        align="center"
                      >
                        <Row gutter={[1, 27]}>
                          {schedules.subRouteSchedules
                            .map((v) => v)
                            .filter((item) => item.subRoute.order > 0)
                            .map((v) => (
                              <Col key={index} span={24}>
                                <Row>
                                  <Col span={8}>
                                    <Text color="success">{`-${v.summary.getOn}`}</Text>
                                  </Col>
                                  <Col span={8}>
                                    <Text color="error">{`${v.summary.getOff}`}</Text>
                                  </Col>
                                  <Col span={8}>
                                    <Text color="black">{`${v.summary.available}`}</Text>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          {schedules.subRouteSchedules
                            .slice(-1)
                            .map((value) => (
                              <CardStyleSecondary
                                key={value.id}
                                padding="0.2rem 1rem"
                                className="ml-1 mr-1"
                                height="30px"
                                color="white"
                                borderColor="lightGray"
                                background="lightGray"
                              >
                                Destination
                              </CardStyleSecondary>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                    <Divider className="mt-minus-1" />
                    <Text weight="600" color="darkBlue">
                      Close route
                    </Text>
                    <Row className="mt-1">
                      {schedules.subRouteSchedules.filter(
                        (item) =>
                          item.isActive === false && item.subRoute.order > 0
                      ).length > 0 ? (
                        schedules.subRouteSchedules
                          .filter(
                            (item) =>
                              item.isActive === false && item.subRoute.order > 0
                          )
                          .map((v, i) => (
                            <Label
                              title={`${v.arrivalTime.slice(0, -3)} ${
                                v.subRoute?.from.code
                              }`}
                              key={`route-${i}`}
                              className="mr-1"
                            >
                              {`${v.arrivalTime.slice(0, -3)} ${
                                v.subRoute?.from.code
                              }`}
                            </Label>
                          ))
                      ) : (
                        <Col span={24}>
                          <Row justify="center">
                            <Text color="monoGray">No close route</Text>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </CardStyleSecondary>
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Row className="mt-2 mb-2" align="middle" justify={"center"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Row>
              </Col>
            )}
          </Row>
        </Spin>
      )}

      {type === "weekly" && (
        <>
          <TableSchedule
            rowKey={(record) => record.id}
            scroll={{ x: 1300 }}
            columns={columns}
            dataSource={schedule?.data}
            loading={isLoadingSchedule || isFetchingSchedule}
          />
        </>
      )}

      {modal.type === "create" && modal.isShow && (
        <Modal
          title="ADD SCHEDULE"
          textOk="Create"
          visible={modal.isShow}
          onOk={() => form.submit()}
          onCancel={() => onCloseModal()}
          width={1200}
          loading={isLoadingCreateSchedule}
        >
          <Spin
            spinning={isLoadingCreateSchedule}
            tip="Loading..."
          >
            <Row>
              <Text color="monoGray">Description</Text>
            </Row>
            <Row className="mt-2">
              <Col span={24}>
                <FormSchedule
                  rowKey={(record) => record.id}
                  loading={isLoadingCreateSchedule}
                  type="create"
                  form={form}
                  onFinish={onFinishFormSchedule}
                  dateFormat={dateFormat}
                  timeFormat={timeFormat}
                  data={modal.data}
                  disabled={disabled}
                  startDateSchedule={startDateSchedule}
                  getDisabledHours={getDisabledHours}
                  getDisabledMinutes={getDisabledMinutes}
                  onChangeRoutes={onChangeRoutes}
                  onChangeDepartureTime={onChangeDepartureTime}
                  onChangeStartDate={onChangeStartDate}
                  onChangeTransports={onChangeTransports}
                  getMaxSeat={getMaxSeat}
                  onChangeCheckbox={onChangeCheckbox}
                  onChangeArrivalTimePicker={onChangeArrivalTimePicker}
                  onSelectDepartureTime={onSelectDepartureTime}
                  onSelectArrivalTime={onSelectArrivalTime}
                  separateRoute={separateRoute}
                  checked={checkbox}
                  selected={selected}
                  route={routes?.data}
                  transport={transports?.data}
                  isFetchingTransport={isFetchingTransport}
                />
              </Col>
            </Row>
          </Spin>
        </Modal>
      )}
    </>
  );
};

Schedule.layout = "MainLayout";

export default Schedule;
