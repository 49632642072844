import { Row } from 'antd'
import { memo } from 'react'
import PropTypes from 'prop-types'

import { TableStyle } from './style'

const TableSchedule = ({ showPage, page, ...props }) => {
  return (
    <>
      <TableStyle
        pagination={false}
        {...props}
      />
    </>
  )
}

TableSchedule.propTypes = {
  showPage: PropTypes.bool,
  page: PropTypes.object,
}

TableSchedule.defaultProps = {
  showPage: true,
}

export default memo(TableSchedule)
