import { stringify } from "query-string";
import api from "../index";

export const routeController = () => {
  return {
    getRoute: async ({ limit = 10, page = 1 }) => {
      const params = stringify({ limit, page });
      return await api.get(`routes?${params}`);
    },
    createRoutes: async (data) => {
      return await api.post(`/routes`, data);
    },
    updateRoutesById: async ({ id, data }) => {
      return await api.patch(`/routes/${id}`, data);
    },
    deleteRoutesById: async (id) => {
      return await api.delete(`/routes/${id}`);
    },
    searchRoutes: async ({ limit, page = "", keyword = "" }) => {
      const params = stringify({ limit, page, keyword });
      if (keyword !== "") return await api.get(`/routes/keyword?${params}`);
    },
  };
};
