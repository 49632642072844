import { Pagination, Table as AntdTable } from 'antd'
import styled from 'styled-components'

export const TableStyle = styled(AntdTable)`
  && {
    .ant-table-thead > tr > th {
      color: ${(props) => props.theme.color.black};
      font-size: ${(props) => props.theme.fontSize.xSmall};
      font-weight: 600;
      padding: 12px;
      text-transform: uppercase;
      background-color: ${(props) => props.theme.color.lightBlue};
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column)::before {
      width: 0;
    }

    .ant-table-tbody {
      background-color: ${(props) => props.theme.color.white};
    }

    .ant-table-tbody > tr > td {
      border: none;
      padding: 10px 16px 0px;
      vertical-align: top;
    }

    .ant-table-tbody tr:nth-child(2n) td {
      background-color: ${(props) => props.theme.color.white};
      border: none;
    }

    tbody > tr:hover > td {
      background: #fff;
    }
  }
`